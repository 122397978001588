import { ReactElement } from "react";
import Container from "../Container";
import ScrollToTop from "../ScrollToTop";
import BackToTop from "../BackToTop";
// import Header from "../Header";
// import Footer from "../Footer";

type Props = {
  children: ReactElement;
};

const Layout = ({ children }: Props) => (
  <>
    {/* <Header /> */}
    <Container>
      <ScrollToTop>{children}</ScrollToTop>
      <BackToTop />
    </Container>
    {/* <Footer /> */}
  </>
);

export default Layout;
