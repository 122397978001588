export const HOME = "/";
const SECTION1 = "/#section1";
const SECTION2 = "/#section2";
const SECTION3 = "/#section3";
const SECTION4 = "/#section4";
const SECTION5 = "/#section5";

export const HOME_NAVBAR_LINKS = [
  { to: SECTION1, text: "Section 1" },
  { to: SECTION2, text: "Section 2" },
  { to: SECTION3, text: "Section 3" },
  { to: SECTION4, text: "Section 4" },
  { to: SECTION5, text: "Section 5" },
];
