import { ReactNode, ReactElement } from "react";
import styles from "./Container.module.scss";

type Props = {
  children: ReactNode;
};

const Container = ({ children }: Props): ReactElement => (
  <main className={styles.container}>
    <div className={styles.content}>{children}</div>
  </main>
);

export default Container;
