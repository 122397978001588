import { ReactElement } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HOME } from "../../constants";
import Main from "../../pages/Main";
import Error404 from "../../pages/Error404";
import Layout from "../Layout";

const App = (): ReactElement => (
  <Router>
    <Layout>
      <Routes>
        <Route path={HOME} element={<Main />} />
        <Route element={<Error404 />} />
      </Routes>
    </Layout>
  </Router>
);

export default App;
